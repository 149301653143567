import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faWrench } from '@fortawesome/pro-regular-svg-icons';
import { Redirect, Route, Switch } from 'react-router-dom';
import { LayoutContent } from '~/common/components';
import { Avatars, Datepickers, DragDropStand, Inputs, Interactives, PopoverStand, Range, Selects, Testing, Tooltips, Tags, Toasts, } from './Stands';
export const UIKIT_URL = '/ui-kit';
const stands = [
    {
        label: 'Inputs',
        path: `${UIKIT_URL}/inputs`,
        component: Inputs,
    },
    {
        label: 'Selects',
        path: `${UIKIT_URL}/selects`,
        component: Selects,
    },
    {
        label: 'Interactives',
        path: `${UIKIT_URL}/interactives`,
        component: Interactives,
    },
    {
        label: 'Tooltips',
        path: `${UIKIT_URL}/tooltips`,
        component: Tooltips,
    },
    {
        label: 'Avatars',
        path: `${UIKIT_URL}/avatars`,
        component: Avatars,
    },
    {
        label: 'Range select',
        path: `${UIKIT_URL}/range`,
        component: Range,
    },
    {
        label: 'Popover',
        path: `${UIKIT_URL}/popover`,
        component: PopoverStand,
    },
    {
        label: 'Date pickers',
        path: `${UIKIT_URL}/date-pickers`,
        component: Datepickers,
    },
    {
        label: 'Drag and drop',
        path: `${UIKIT_URL}/drag-drop`,
        component: DragDropStand,
    },
    {
        label: 'Testing',
        path: `${UIKIT_URL}/testing`,
        component: Testing,
    },
    {
        label: 'Tags',
        path: `${UIKIT_URL}/tags`,
        component: Tags,
    },
    {
        label: 'Toasts',
        path: `${UIKIT_URL}/toasts`,
        component: Toasts,
    },
];
const nav = {
    icon: faWrench,
    items: stands.map(({ label, path }) => ({ label, path })),
    mainPath: `${UIKIT_URL}`,
    path: UIKIT_URL,
    label: 'UI Kit',
};
const UiKit = () => (_jsx(LayoutContent, { children: _jsxs(Switch, { children: [stands.map(({ path, component }) => (_jsx(Route, { path: path, component: component }, path))), _jsx(Redirect, { to: stands[0].path })] }) }));
export const uiKitModule = {
    canShow: () => true,
    route: {
        path: UIKIT_URL,
        component: UiKit,
    },
    nav,
};
