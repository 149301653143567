import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faCar, faCarrot, faCoffeePot, faFrenchFries, faPlane, faWasher, } from '@fortawesome/pro-regular-svg-icons';
import { useState } from 'react';
import { IconBox, RevisionSelect, SelectField, } from '~/common/components';
import { Multiselect } from '~/common/kits/table';
import { cx, intersperse, noop } from '~/common/utils';
import { InlineSelectField } from '~/orders/Order';
const options = [
    { value: 1, name: 'potato' },
    { value: 2, name: 'carrot' },
    { value: 3, name: 'washing machine' },
    { value: 4, name: 'plane' },
    { value: 5, name: 'car' },
];
const optionToIcon = {
    potato: faFrenchFries,
    carrot: faCarrot,
    'washing machine': faWasher,
    plane: faPlane,
    car: faCar,
};
const getHighlightedText = (str, match, Component = ({ children }) => _jsx("b", { children: children })) => {
    return match !== null ? intersperse(str.split(match), _jsx(Component, { children: match })) : str;
};
const itemRenderer = ({ option, inputValue, className, ...props }) => {
    // simpler way to do it, requires dangerouslySetInnerHTML though
    // const highlighted = option.name.replace(re, (m) => `<b>${m}</b>`);
    // proper way
    const re = new RegExp(inputValue, 'g');
    const match = option.name.match(re);
    const highlighted = getHighlightedText(option.name, match ? match[0] : null, (props) => (_jsx("span", { className: "font-semibold", children: props.children })));
    return (_jsxs("li", { ...props, className: cx(className, 'h-6'), children: [_jsx(IconBox, { size: "l", icon: optionToIcon[option.name], className: "mr-1" }), _jsx("span", { children: highlighted })] }));
};
const notFoundRenderer = (props) => (_jsxs("li", { className: cx(props.className, 'h-6'), children: [_jsx(IconBox, { size: "l", icon: faCoffeePot, className: "mr-1" }), "Oopsie daisy :( there's no teapot with that name"] }));
export const Selects = () => {
    const [value, setValue] = useState(1);
    const [multiselectValue, setMultiselectValue] = useState(undefined);
    return (_jsxs("div", { className: "bg-greyscale-0 p-2 rounded", children: [_jsx("div", { className: "font-brand-h1 w-full mb-3", children: "Selects" }), _jsxs("div", { className: "grid grid-cols-2 gap-4 w-[1200px]", children: [_jsxs("div", { children: [_jsx(Multiselect, { placeholder: "Multiple", onChange: setMultiselectValue, onBlur: noop, value: multiselectValue, options: options }), _jsx(SelectField, { onChange: setValue, onBlur: noop, value: value, options: options, errors: ['testing'], disabled: false, touched: false, placeholder: "test", title: "test" }), _jsx(InlineSelectField, { onChange: setValue, onBlur: noop, value: value, options: options, errors: ['testing'], disabled: false, touched: false, placeholder: "test", title: "test" })] }), _jsxs("div", { className: "h-[200px] text-greyscale-200 bg-greyscale-600 p-4 rounded", children: [_jsx("div", { className: "font-brand-t2m", children: "Revision selector" }), _jsxs("div", { className: "flex items-baseline gap-3 mt-2", children: [_jsx(RevisionSelect, { className: "mr-2 w-32", onChange: setValue, onBlur: noop, value: value, options: options }), _jsx("span", { className: "whitespace-nowrap", children: "something else" })] })] }), _jsxs("div", { className: "h-[200px] bg-greyscale-100 p-4 rounded", children: [_jsx("div", { className: "font-brand-t2m mb-2", children: "Custom item renderer" }), _jsx(SelectField, { onChange: setValue, onBlur: noop, value: value, options: options, errors: null, disabled: false, touched: false, containerClassName: "max-h-[192px]", itemRenderer: itemRenderer, notFoundRenderer: notFoundRenderer })] })] })] }));
};
