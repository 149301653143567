import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useForm } from 'formoid';
import { Button, InputField, Toast, SelectField, SwitcherField } from '~/common/components';
export const Toasts = () => {
    const { values, fieldProps } = useForm({
        initialValues: {
            title: 'test title',
            subTitle: 'test subtitle',
            link: '/orders',
            type: 'warning',
            reversed: false,
        },
        validationStrategy: 'onSubmit',
        validators: () => ({
            title: null,
            link: null,
            message: null,
            subTitle: null,
            type: null,
            reversed: null,
        }),
    });
    return (_jsxs("div", { className: "bg-greyscale-0 p-2 rounded space-y-3", children: [_jsx("div", { className: "font-brand-h1 w-full mb-3 truncate", children: "Toasts" }), _jsxs("div", { className: "grid mt-8 w-fit gap-2", children: [_jsx(InputField, { ...fieldProps('title'), type: "text", title: "Title" }), _jsx(InputField, { ...fieldProps('subTitle'), type: "text", title: "Sub title" }), _jsx(InputField, { ...fieldProps('link'), type: "text", title: "Link" }), _jsx(SelectField, { ...fieldProps('type'), title: "Type", options: [
                            { name: 'Error', value: 'error' },
                            { name: 'Default', value: 'default' },
                            { name: 'Info', value: 'info' },
                            { name: 'Success', value: 'success' },
                            { name: 'Warning', value: 'warning' },
                        ] }), _jsx(SwitcherField, { ...fieldProps('reversed'), title: "Is reversed" }), _jsx(Button, { color: "secondary", size: "medium", onClick: () => Toast[values.type](values), children: _jsx("span", { className: "font-brand-t1m", children: "\uD83E\uDDC8 + \uD83C\uDF5E = \uD83E\uDD6A" }) })] })] }));
};
